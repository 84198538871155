@import '../../utils/media.scss';
@import '../../utils/colors.scss';

.Navbar {
    width: 100%;

    &-navigation {
        .navbar {
            padding: 0;

            .bg-div {
                width: 100%;
                text-align: right;
                background-color: white;
                top: 0;
                left: 0;
                z-index: 10;
                padding-top: .75rem;
                padding-right: 1rem;
                padding-bottom: 1rem;

                & button:focus {
                    outline: none;
                }

                & .icon-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;

                    & i {
                        background-color: $darkGrey;
                        width: 28px;
                        height: 3px;
                        margin: 4px;
                        border-radius: 4px;
                        transition: all ease .4s;
                    }

                    .open:nth-child(1) {
                        transform: rotate(45deg) translateY(15px);
                        margin-left: 25px;
                        opacity: .85;
                    }

                    .open:nth-child(2) {
                        opacity: 0;
                    }

                    .open:nth-child(3) {
                        transform: rotate(-45deg) translateY(-15px);
                        margin-left: 25px;
                        opacity: .85;
                    }

                    .close:nth-child(1) {
                        transform: rotate(0) translateY(0);
                        opacity: .75;
                    }

                    .close:nth-child(2) {
                        opacity: .75;
                    }

                    .close:nth-child(3) {
                        transform: rotate(0) translateY(0);
                        opacity: .75;
                    }
                }

                @media (min-width: $tablet) {
                    display: none;
                }
            }

            .navbar-collapse {
                position: absolute;
                top: 1.5rem;
                left: 0;
                width: 100%;

                .navbar-nav {
                    position: relative;
                    margin-left: auto;
                    margin-right: auto;

                    .nav-link {
                        color: white;
                        font-size: 20px;
                        transition: color .4s ease-in-out;
                        border-bottom: 1px solid transparent;

                        &:hover {
                            color: $lightGrey;
                        }

                        @media (min-width: $tabletHorizontal) {
                            margin-left: .7rem;
                            margin-right: .7rem;
                            font-size: 22px;
                        }

                        @media (min-width: $laptop) {
                            font-size: 25px;
                        }
                    }

                    .active {
                        border-bottom: 1px solid white;

                        &:hover {
                            color: white;
                        }
                    }
                }
            }
        }
    }

    &-mobile {
        background-color: white;
        position: fixed;
        width: 100vw;
        top: 50px;
        z-index: 9000;
        padding-top: .25rem;
        height: 100vh;
        text-align: center;
        overflow-x: scroll;
        padding-bottom: 2.5rem;
        animation: Move .5s ease-in;

        @keyframes Move {
            from {
                top: -80%;
                background-color: transparent;
            }

            to {
                top: 70px;
                background-color: white;
            }
        }

        & .nav-link {
            font-size: 20px;
            color: $darkGrey;
            padding: 1rem;
        }
    }
}