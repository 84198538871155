$pink: #DB8A8C;
$textGrey: #303030;
$darkGrey: #383f47;
$mediumGrey: #7e7d7d;
$lightGrey: #AFAFAF;
$mint: #a2c0b8;

$linkPink: #db8a8be3;
$linkPinkHover: #b97273ef;
$linkMint: #87a39c;
$linkMintHover: #6a8d84f6;
$linkLight: #9b9999e5;
$linkLightHover: #747373f3;
$linkDark: #31373ee1;
$linkDarkHover: #292d35;