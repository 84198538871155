@import '../../utils/colors.scss';
@import '../../utils/media.scss';

.FiltersBar {

    a {
        color: $textGrey;
        font-weight: 600;

        &:hover {
            text-decoration: none;
            color: $mint;
        }
    }

    &-mobile {

        .btn {
            width: 100%;
            color: $darkGrey;
            background-color: $mint;
            text-align: end;
            padding: 1rem;
            border-radius: 0;
            margin: 0;

            &:active,
            &:hover {
                color: $textGrey;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .dropdown-menu {
            top: 95%;
            right: 0;
            text-align: end;
            padding: .5rem;
            border-radius: 0;
            background-color: $mint;
        }

        .dropdown-item {
            padding: .5rem;
            white-space: normal;
            color: $textGrey;

            &:hover {
                background-color: $mint;
                color: $textGrey;
            }
        }

        @media (min-width: $laptop) {
            display: none;
        }
    }

    &-desktop {
        display: none;

        @media (min-width: $laptop) {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            background-color: white;
            margin: 3rem auto;
            padding: 2rem 1rem;
            width: 100%;

            .icon-wrapper {
                display: grid;
                width: 1rem;
                height: 1rem;
                cursor: pointer;
                margin: .725rem;

                & img {
                    margin-bottom: 4px;
                }
            }

            .dropdown-menu {
                border: none;
                border-radius: 0;
                text-align: right;
                padding-top: 2.5rem;
                padding-bottom: 0;
                background-color: rgba(255, 255, 255, 0.9);
                top: 85%;
                left: auto;
                right: 0;
                margin-right: -35px;
                margin-top: 0;
            }

            .dropdown-item {
                padding: .5rem 1.75rem;
                border-bottom: 1px solid rgba(168, 168, 168, 0.25);

                &:hover {
                    background-color: transparent;
                }
            }

            a.active {
                color: $mint;
                background-color: transparent;
            }
        }

        @media (min-width: $desktop) {
            padding: 2rem 13vw;
        }

        @media (min-width: $desktopL) {
            width: 55vw;
            padding: 2rem 5vw;
        }
    }
}