@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&family=Oswald:wght@700&display=swap');
@import './utils/colors.scss';

html * {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  color: $textGrey;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}