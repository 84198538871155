@import '../../utils/media.scss';

.Header {
    background: url(./para-mloda-czarymarry-organizacja-slubow-sesji.jpg) center center no-repeat;
    background-size: cover;
    object-fit: cover;
    width: 100%;
    height: 29rem;
    margin-bottom: .5rem;

    @media (min-width: $mobileHorizontal) {
        height: 32rem;
    }

    @media (min-width: $tablet) {
        margin-top: 0;
    }

    @media (min-width: $laptop) {
        height: 48rem;
    }
}