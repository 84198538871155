
.VideoEmbed-container {
    overflow: hidden;
    padding-bottom: 58%;
    position: relative;
    height: 0;

    & iframe {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }
}