@import '../../utils/media.scss';
@import '../../utils/colors.scss';

.PostPreview {
    position: relative;
    top: -7rem;
    left: -2px;

    &-mobile {
        background-color: pink;
        margin: 1.5rem auto 0 auto;
        height: 100vw;
        width: 100vw;
        position: relative;
        left: -18px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .overlay-div {
            height: 100%;
            background-color: rgba(58, 58, 58, 0.4);
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
            padding: 2rem 1.5rem;
            display: grid;

            h2,
            p {
                color: white;
            }

            h2 {
                font-size: 25px;
                font-weight: 600;
                display: flex;
                align-self: end;
                line-height: 1.4em;
            }

            p {
                font-size: 13px;
            }

            @media (min-width: $mobileM) {
                padding: 2.5rem;
            }
        }

        @media (min-width: $tablet) {
            display: none;
        }
    }

    &-desktop {
        display: none;

        @media (min-width: $tablet) {
            display: grid;
            margin-left: auto;
            margin-right: auto;
            background-color: white;
            width: 100%;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            grid-template-areas:
                "pic text";
            border: 1px solid $lightGrey;
            margin-bottom: 2rem;
            max-height: 22rem;

            .content-div {
                grid-area: text;
                padding: 2rem 1.5rem;
                display: grid;
                align-items: center;
                margin-top: auto;
                margin-bottom: auto;

                h2 {
                    font-size: 22px;
                    font-weight: 600;
                    margin-bottom: 0;

                    @media (min-width: $laptop) {
                        font-size: 28px;
                    }
                }

                p {
                    font-size: 14px;

                    @media (min-width: $laptop) {
                        font-size: 16px;
                    }
                }

                hr {
                    margin: 1.5rem 0;
                    width: 95%;
                }

                @media (min-width: $laptop) {
                    padding: 3rem 2rem;
                }
            }

            img {
                grid-area: pic;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        @media (min-width: $laptop) {
            max-height: 24rem;
        }

        @media (min-width: 1200px) {
            width: 58rem;
        }
    }

    @media (min-width: $laptop) {
        top: -3rem;
    }
}