@import '../../utils/media.scss';

.ArrowToTop {
    position: fixed;
    bottom: 10%;
    right: 6%;
    z-index: 8000;

    img {
        transform: rotate(180deg);
        width: 3.1rem;
        height: 3.1rem;
        opacity: .7;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }

        @media (min-width: $tabletHorizontal) {
            width: 6rem;
            height: 6rem;
        }
    }

    @media (min-width: $tabletHorizontal) {
        bottom: 5%;
        right: 2%;
    }
}