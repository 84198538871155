@import '../../utils/colors.scss';
@import '../../utils/media.scss';

.PostPreviewSmall {
    background-color: white;
    border: 1px solid $lightGrey;
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;

    img {
        display: block;
        width: 100%;
        height: 14rem;
        object-fit: cover;
    }

    h2 {
        font-size: 1.2rem;
        padding: 2rem 1.5rem;
        line-height: 1.5em;
        display: flex;
        justify-self: center;
    }

    @media (min-width: $laptop) {

        h2 {
            padding: 1.75rem 1.25rem;
        }
    }
}