@import './utils/media.scss';
@import './utils/colors.scss';

.App {
  width: 100%;

  .custom-link {
    text-decoration: none;
  }

  .number {
    font-family: 'Oswald', sans-serif;
    font-size: 59px;

    @media (min-width: $tablet) {
      font-size: 100px;
    }

    @media (min-width: $laptop) {
      font-size: 176px;
    }
  }

  .page-header {
    display: flex;

    @media (min-width: $tablet) {
      align-items: baseline;
    }
  }

  .page-title {
    font-size: 43px;
    line-height: 1em;
    font-weight: bold;
    letter-spacing: -0.1em;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 12px;
    color: $darkGrey;

    @media (min-width: $tablet) {
      font-size: 100px;
    }

    @media (min-width: $laptop) {
      font-size: 176px;
    }
  }

  .main-header {
    font-size: 19px;
    line-height: 1.4em;
    letter-spacing: 0.05em;
    margin-bottom: 1.5rem;
    overflow-wrap: break-word;

    @media (min-width: $tabletHorizontal) {
      font-size: 25px;
      margin-bottom: 3rem;
    }

    @media (min-width: $laptop) {
      margin-bottom: 4rem;
      margin-right: 2rem;
    }

    @media (min-width: $desktop) {
      margin-top: 4rem;
    }
  }

  .main-div {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 3rem;

    @media (min-width: $laptop) {
      padding: 0 7vw;
      margin-bottom: 5%;
    }

    @media (min-width: 1200px) {
      padding: 0 15vw;
    }

    @media (min-width: $desktop) {
      padding: 0 18vw;
    }
  }

  p {
    letter-spacing: 0.05em;
    line-height: 1.4em;
    overflow-wrap: break-word;
    font-size: 17px;

    @media (min-width: $tabletHorizontal) {
      font-size: 20px;
    }
  }

  .bold {
    font-weight: 600;
  }

  .pink {
    color: $pink;
  }

  .dark {
    color: $darkGrey;
  }

  .grey {
    color: $lightGrey;
  }

  .mint {
    color: $mint;
  }

  .bgPink {
    background-color: $pink;
  }

  .bgMint {
    background-color: $mint;
  }

  .bgGrey {
    background-color: $lightGrey;
  }

  .bgDark {
    background-color: $darkGrey;
  }

  .bgMintLight {
    background-color: #a6c2bbbd;
  }

  .bgPinkLight {
    background-color: #db8a8b44;
  }

  .bgGreyLight {
    background-color: #afafaf70;
  }

  .bgDarkLight {
    background-color: #31373e50;
  }

  .shadowBox {
    box-shadow: 0px 0px 14px 1px rgba(141, 141, 141, 0.4);
  }

  .linkPink,
  .linkMint,
  .linkLight,
  .linkDark {
    text-decoration: none;
    font-weight: 600;

    &:hover {
      text-decoration: none;
      font-weight: 600;
    }
  }

  .linkPink {
    color: $linkPink;

    &:hover {
      color: $linkPinkHover;
    }
  }

  .linkMint {
    color: $linkMint;

    &:hover {
      color: $linkMintHover;
    }
  }

  .linkLight {
    color: $linkLight;

    &:hover {
      color: $linkLightHover;
    }
  }

  .linkDark {
    color: $linkDark;

    &:hover {
      color: $linkDarkHover;
    }
  }

  .extra-content {
    width: 90vw;
    margin: 3rem auto;
    padding: 2rem 1.25rem;

    @media (min-width: $tablet) {
      width: 75vw;
      padding: 2rem;
    }

    @media (min-width: $laptop) {
      width: 34rem;
      padding: 2rem 2.5rem;
    }

    @media (min-width: $desktop) {
      width: 38rem;
    }
  }
}