@import '../../utils/colors.scss';

.CustomLink {
    color: $mediumGrey;
    text-decoration: underline;

    &:hover {
        color: $textGrey;
        text-decoration: underline;
    }
}

.bgColorPink,
.bgColorMint,
.bgColorLight,
.bgColorDark {
    text-decoration: none;
    font-weight: 600;

    &:hover {
        text-decoration: none;
        font-weight: 600;
    }
}

.bgColorPink {
    background-color: #db8a8b77;
    color: $darkGrey;

    &:hover {
        background-color: #db8a8b94;
        color: $textGrey;
    }
}

.bgColorMint {
    background-color: #adc8c1b6;
    color: $darkGrey;

    &:hover {
        background-color: #adc8c1f6;
        color: $textGrey;
    }
}

.bgColorLight {
    background-color: #afafaf94;
    color: $darkGrey;

    &:hover {
        background-color: #afafafbd;
        color: $textGrey;
    }
}

.bgColorDark {
    background-color: #31373e62;
    color: $darkGrey;

    &:hover {
        background-color: #31373e8a;
        color: $textGrey;
    }
}