@import '../../utils/colors.scss';
@import '../../utils/media.scss';

.OurCouples {

    .number {
        color: $lightGrey;
    }

    .smaller {
        @media (min-width: $tablet) {
            font-size: 112px;
        }

        @media (min-width: $laptop) {
            font-size: 145px;
            margin-left: 30px;
        }

        @media (min-width: $desktop) {
            font-size: 153px;
        }

        @media (min-width: 1950px) {
            font-size: 176px;
        }
    }

    &-content {

        .main-header {
            @media (min-width: $laptop) {
                margin-left: 2rem;
            }
        }

        &_grid {
            margin-bottom: 3rem;
            margin-top: 1rem;
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: .5rem;
            grid-template-rows: repeat(2, auto);
            grid-template-areas:
                "description"
                "photos";

            & .description {
                grid-area: description;
                display: grid;

                & .content-box {
                    margin: 1.5rem;

                    .top-h,
                    .small-h,
                    p {
                        color: white;
                    }

                    .top-h,
                    .small-h {
                        font-size: 18px;
                        font-weight: 600;
                        margin-bottom: .5rem;
                    }

                    .small-h {
                        margin-top: 2rem;
                    }

                    .top-h {
                        @media (min-width: $tablet) {
                            font-size: 22px;
                            margin-bottom: 1rem;
                        }

                        @media (min-width: $laptop) {
                            margin-bottom: 1.5rem;
                        }

                        @media (min-width: $desktop) {
                            font-size: 24px;
                            margin-bottom: 2rem;
                            text-align: center;
                        }
                    }

                    p {
                        font-size: 16px;
                        letter-spacing: -0.05em;
                        margin-bottom: 0;

                        @media (min-width: $laptop) {
                            font-size: 17px;
                        }
                    }

                    a {
                        color: white;
                        text-decoration: underline;

                        &:hover {
                            color: $mediumGrey;
                        }
                    }

                    a.linkWhite {
                        text-decoration: none;

                        &:hover {
                            color: white;
                            text-decoration: underline;
                        }
                    }

                    @media (min-width: $laptop) {
                        margin: auto 1rem auto 1.5rem;
                    }

                    @media (min-width: $desktopL) {
                        margin: auto 2.5rem;
                    }
                }
            }

            .photos {
                grid-area: photos;
            }

            .mainPic-wrapper {
                display: flex;
                height: auto;

                & img {
                    object-fit: cover;
                    width: 100%;
                    height: inherit;
                }
            }

            @media (min-width: $tablet) {
                width: 77%;
                margin-left: auto;
                margin-right: auto;
            }

            @media (min-width: $laptop) {
                margin-bottom: 3rem;
                width: 60rem;
                grid-template-columns: 2fr 1.4fr;
                grid-template-rows: auto;
                grid-template-areas:
                    "photos description";
            }

            @media (min-width: $desktopL) {
                grid-template-columns: 2fr 1.2fr;
            }
        }

        @media (min-width: $laptop) {
            .flipped {
                grid-template-columns: 1.4fr 2fr;
                grid-template-areas:
                    "description photos";
            }

            .with-min-height {
                min-height: 32rem;
            }
        }
    }
}

.custom-div {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 3rem;

    @media (min-width: $laptop) {
        padding: 0 5vw;
        margin-bottom: 5%;
    }

    @media (min-width: $desktop) {
        padding: 0 17vw;
    }

    @media (min-width: $desktopL) {
        padding: 0 18vw;
    }
}