@import '../../utils/colors.scss';
@import '../../utils/media.scss';

.HowWeWork {
    margin-top: 1.5rem;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 3rem;

    .number {
        color: $mint;
    }


    .smaller {
        @media (min-width: $tablet) {
            font-size: 90px;
        }

        @media (min-width: $laptop) {
            font-size: 100px;
            margin-left: 30px;
        }

        @media (min-width: $desktop) {
            font-size: 112px;
        }

        @media (min-width: 1650px) {
            font-size: 135px;
        }

        @media (min-width: 1950px) {
            font-size: 160px;
        }
    }

    &-content {
        padding-top: 2rem;

        .number-small,
        .header-small {
            font-family: 'Oswald', sans-serif;
            font-weight: 600;
            margin-top: 1.5rem;
            margin-bottom: .75rem;
        }

        .number-small {
            font-size: 39px;

            @media (min-width: $tablet) {
                font-size: 50px;
                margin: 0;
            }

            @media (min-width: $desktop) {
                font-size: 176px;
            }
        }

        .header-small {
            font-size: 27px;

            @media (min-width: $tablet) {
                font-size: 37px;
            }

            @media (min-width: $desktop) {
                font-size: 60px;
                margin-top: 3rem;
                margin-bottom: 2rem;
            }
        }

        &_grid {

            @media (min-width: $desktop) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: repeat(2, 30rem);
                grid-template-areas:
                    "divOne divTwo"
                    "divThree divFour";

                .div-one {
                    grid-area: divOne;
                }

                .div-two {
                    grid-area: divTwo;
                }

                .div-three {
                    grid-area: divThree;
                    padding-left: 2rem;
                }

                .div-four {
                    grid-area: divFour;
                    padding-right: 5rem;
                }

                .div-one,
                .div-three {
                    text-align: right;
                    padding-right: 2rem;
                    border-right: 2px solid $darkGrey;
                    margin-bottom: 3.25rem;
                }

                .div-two,
                .div-four {
                    padding-left: 2rem;
                    border-left: 1px solid $darkGrey;
                    margin-bottom: 3.25rem;
                }

                p {
                    padding-top: 1rem;
                }
            }

            @media (min-width: 1750px) {
                margin-left: auto;
                margin-right: auto;
                width: 64rem;
            }
        }

        .work-p {
            @media (min-width: $desktop) {
                padding-right: 8rem;
                padding-left: 4rem;
            }
        }
    }

    @media (min-width: $laptop) {
        padding: 0 7vw;
        margin-bottom: 7%;
    }

    @media (min-width: $desktop) {
        padding: 0 16vw;
    }

    @media (min-width: $desktopL) {
        padding: 0 18vw;
    }
}