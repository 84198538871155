@import '../../utils/colors.scss';
@import '../../utils/media.scss';

.AboutUs {

    .number {
        color: $pink;
    }

    &-content {
        padding-top: 2rem;

        h1 {
            & span {
                @media (min-width: $laptop) {
                    display: none;
                }

                @media (min-width: $desktop) {
                    display: initial;
                }

                @media (min-width: $desktopL) {
                    display: none;
                }
            }
        }

        h2 {
            font-size: 37px;
            font-weight: bold;

            @media (min-width: $tablet) {
                font-size: 100px;
            }

            @media (min-width: $laptop) {
                font-size: 155px;
            }

            @media (min-width: $desktop) {
                font-size: 176px;
            }
        }

        & .content-div {
            margin-top: 1rem;

            .pic {
                width: 100%;
                margin-bottom: 1rem;

                img {
                    width: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                }
            }

            @media (min-width: $tablet) {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
            }

            @media (min-width: $laptop) {
                width: 100%;
                position: relative;
                display: grid;
                align-items: end;
                grid-template-rows: auto;
                grid-column-gap: 2rem;
                margin-left: 0;
                margin-right: 0;
            }

            @media (min-width: $desktopL) {
                grid-template-columns: 1fr 1fr;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                width: 80%;
            }
        }

        & .kasia {
            @media (min-width: $laptop) {
                grid-template-columns: 1fr .9fr;
                margin-top: 5rem;

                h2 {
                    position: absolute;
                    top: -9%;
                    right: 0%;
                }
            }

            @media (min-width: 1200px) and (max-width: $desktop) {
                h2 {
                    top: -15%;
                }
            }

            @media (min-width: $desktopL) {
                h2 {
                    right: 10%;
                }
            }
        }

        & .ola {
            @media (min-width: $laptop) {
                grid-template-columns: .9fr 1fr;
                grid-template-areas:
                    "text pic";
                margin-top: 9rem;

                & .pic {
                    grid-area: pic;
                }

                & p {
                    grid-area: text;
                    text-align: right;
                }

                h2 {
                    position: absolute;
                    top: -13%;
                    left: 23%;
                }
            }

            @media (min-width: 1200px) and (max-width: $desktop) {
                h2 {
                    top: -20%;
                }
            }

            @media (min-width: $desktopL) {
                h2 {
                    top: -10%;
                    left: 25%;
                }
            }
        }

        @media (min-width: $tablet) {
            padding: .5rem;
        }
    }
}