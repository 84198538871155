@import '../../utils/colors.scss';
@import '../../utils/media.scss';

.Pagination {
    margin-bottom: 3rem;

    .empty {
        margin-bottom: 7rem;
        padding: 1rem;
        border: 1px solid $lightGrey;
        background-color: white;

        p {
            font-size: 15px;
            margin-bottom: 0;
            text-align: center;
            color: $mediumGrey;

            @media (min-width: $tablet) {
                font-size: 17px;
            }
        }

        @media (min-width: $tablet) {
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            padding: 2rem;
        }

        @media (min-width: $laptop) {
            padding: 4rem;
            position: relative;
        }
    }

    ul {
        justify-content: center;
        align-items: end;
        margin-bottom: 0;
    }

    .icon-wrapper {
        width: 1.2rem;
    }

    .double-chev-left,
    .double-chev-right {
        width: 1.35rem;
    }

    .double-chev-left {
        transform: rotate(90deg);
    }

    .double-chev-right {
        transform: rotate(-90deg);
    }

    .disabled {
        filter: opacity(.5);
    }

    .page-link {
        border: none;

        &:focus {
            box-shadow: none;
        }

        &:hover {
            background-color: transparent;
        }
    }

    .page-item.active .page-link {
        color: $mint;
        font-size: 16px;
        background-color: transparent;
    }

    .numbers-desktop {
        @media (max-width: 767px) {
            display: none;
        }
    }

    .numbers-mobile {
        p {
            margin-top: 7px;
        }

        @media (min-width: $tablet) {
            display: none;
        }
    }

    .page-numbers {
        margin-top: 2px;
        font-size: 16px;
        background-color: transparent;
        color: $textGrey;
        border-color: transparent;
    }
}