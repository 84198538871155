@import '../../utils/media.scss';
@import '../../utils/colors.scss';

.Footer {
    width: 100%;
    display: block;

    &-box {
        position: relative;
        display: flex;
        justify-content: center;

        img {
            width: 100%;
            object-fit: cover;
            height: 35rem;

            @media (min-width: $tablet) {
                height: 45rem;
            }
        }

        &_text {
            position: absolute;
            top: 0;
            height: 30rem;
            display: grid;
            align-items: flex-end;


            & h2 {
                font-size: 36px;
                color: white;
                text-align: center;
                width: 12rem;
                margin: 0 auto 1.85rem auto;

                & span {
                    color: white;
                    font-weight: 600;
                }

                @media (min-width: $mobileL) {
                    font-size: 44px;
                }
            }

            & span.mobile {
                @media (min-width: $tablet) {
                    display: none;
                }
            }

            .bottom {

                & p {
                    color: white;
                    font-size: 19px;
                    text-align: center;
                    margin-bottom: 0;
                }

                padding-top: 35vw;

                @media (min-width: $tablet) {
                    padding-top: 70%;
                }

                // @media (min-width: $desktopL) {
                //     padding-top: 70%;
                // }
            }

            @media (min-width: $tablet) {
                top: 5%;
                height: 40rem;
            }
        }
    }

    &-contact {
        width: 100%;
        height: auto;
        background-color: $mint;
        display: grid;
        align-items: center;
    }

    @media (min-width: $laptop) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
    }

    @media (min-width: $desktopL) {
        width: 92rem;
        margin-left: auto;
        margin-right: auto;
    }
}