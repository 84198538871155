@import '../../../utils/colors.scss';
@import '../../../utils/media.scss';

.btn {
    background-color: white;
    color: $mediumGrey;
    border-top: 1px solid $lightGrey;
    border-bottom: 1px solid $lightGrey;
    border-radius: 0;
    padding: .75rem;
    font-size: 15px;
    font-weight: 400;

    &_greyTheme {
        &:hover {
            color: $linkLightHover;
            border-top: 1px solid $mediumGrey;
            border-bottom: 1px solid $mediumGrey;
        }
    }

    &_darkTheme {
        &:hover {
            color: $linkDarkHover;
            border-top: 1px solid $darkGrey;
            border-bottom: 1px solid $darkGrey;
        }
    }

    &_mintTheme {
        &:hover {
            color: $linkMintHover;
            border-top: 1px solid $mint;
            border-bottom: 1px solid $mint;
        }
    }

    &_pinkTheme {
        &:hover {
            color: $linkPinkHover;
            border-top: 1px solid $pink;
            border-bottom: 1px solid $pink;
        }
    }

    @media (min-width: $tablet) {
        width: 14rem;
        margin-left: 4rem;
        margin-bottom: 2rem;
    }

    @media (min-width: $laptop) {
        margin-left: 10vw;
    }

    @media (min-width: $desktop) {
        margin-left: 14vw;
    }

    @media (min-width: $desktopL) {
        margin-left: 28vw;
    }
}

.RealizationPage {
    width: 100%;
    display: grid;
    padding-bottom: 2rem;

    &-main {
        padding: 2rem 1rem;

        p {
            font-size: 16px;
            font-weight: 300;
            margin-bottom: 2.5rem;
        }

            .header-box {
                width: 100%;
                display: grid;
                grid-template-columns: .6fr 2fr;
                grid-template-rows: auto;
                margin-bottom: 1rem;

                div {
                    display: grid;
                    align-items: center;

                    .author {
                        font-size: 15px;
                        margin-bottom: 0;
                        align-self: end;
                        color: $mediumGrey;
                    }
                }

                @media (min-width: $tablet) {
                    width: 50%;
                    margin-bottom: 2rem;

                    div {
                        display: flex;

                        .author,
                        p {
                            align-self: center;
                        }

                        .author {
                            margin-bottom: 1px;
                        }
                    }
                }
            }
        
        .date {
            font-size: 13px;
            margin-bottom: 0;
            margin-left: 7px;
            align-self: flex-start;
            color: $mediumGrey;
        }

        .post-title {
            font-size: 1.9rem;
            margin-bottom: 2rem;

            @media (min-width: $tablet) {
                font-size: 2.1rem;
            }

            @media (min-width: $laptop) {
                font-size: 2.4rem;
            }
        }

        .subheader {
            font-size: 1.5rem;
            margin-top: 2.25rem;
            margin-bottom: 1.25rem;

            @media (min-width: $laptop) {
                font-size: 1.8rem;
            }
        }

        .extras {
            padding-bottom: 1.5rem;
        }

        img {
            width: 100%;
            object-fit: cover;
        }

        @media (min-width: $tablet) {
            padding: 2rem 4rem;
        }

        @media (min-width: $laptop) {
            margin: 3rem 10vw;
            padding: 3rem 6rem;
            
            &_greyTheme {
                border: 1px solid $lightGrey;
            }

            &_darkTheme {
                border: 1px solid $darkGrey;
            }

            &_mintTheme {
                border: 1px solid $mint;
            }

            &_pinkTheme {
                border: 1px solid $pink;
            }
        }

        @media (min-width: $desktop) {
            margin: 4rem 14vw;
            padding: 5rem 9rem;
        }

        @media (min-width: $desktopL) {
            margin-left: 28vw;
            margin-right: 28vw;
        }
    }
}